import React from 'react'
import ludo from '../image/ludo.png'

function Ludo() {
  return (
    <>
    <img id="img" className="img-fluid text-right" src={ludo} alt="" />

    </>
  )
}

export default Ludo
