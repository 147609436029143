import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import BlueFooter from '../Footer/BlueFooter/BlueFooter'

function Layout( {children}  ) {
  return (
    <>
       <Header/>
             {children}
       <BlueFooter/>
    </>
  )
}

export default Layout
