import React from 'react'
import Layout from '../Layout/Layout'
import '../TermsConditions/Conditions.css'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import logo from '../../image/logo.png'
import { LUDO_API } from '../../config'

function Conditions() {
  const [condition, setCondition] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${LUDO_API}/api/page/termsandcondition/list`);
        const { data } = response;
        console.log(data)
        setCondition([data]);
        setLoading(true);
      } catch (error) {
        console.error('Error fetching privacy policy:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
    {loading ? (
      <>
        {condition.map((record, i) => (
          <div key={i} className='privacy-policy'>
            <Layout>
              <div className='banner-about'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-8 m-auto'>
                      <img className='logo m-auto display-none' src={logo} />
                      <div className='border-logo m-auto mt-3 display-none mb-5'></div>
                      <h1>{record.h1}</h1>
                      <p >{record.h1_text}</p>
                      <Link className='text-decoration-none' to={record.button_text1_url}>
                        <button className='about-btn '>
                          <span>{record.button_text1}</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className='wrap-2'>
                <div className='container'>
                  <div className="privacy-policy-wraper">
                    <h2>{record.content1}</h2>
                    <p>{/* Complete this with the actual content */}</p>
                  </div>
                </div>
              </div>
            </Layout>
          </div>
        ))}
      </>
    ) : (
      <p>Loading...</p>
    )}
  </>
  )
}

export default Conditions
