import React, { useEffect, useState } from "react";
import "./Home.css";
import Ludo from "../Ludo";
import Layout2 from '../Layout/Layout2'
import axios from "axios";
import { LUDO_API } from "../../config";
import { useNavbar } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

function Home() {
  const[data,setData] = useState({})
  const nav = useNavigate()
  
  useEffect(()=>{
    axios.get(LUDO_API + "/api/page/home/list")
    .then(response=>{
      console.log(response.data)
      setData(response.data)
    })
    .catch(err=>{
      console.log(err)
    })
  },[])
  return (
    <Layout2>
    <div className="banner">
    <div className="container-fluid home">
      <div className="row">
        <div className="col-lg-6">
           <div className="home-logo-wraper">
            <img class="logo m-auto display-none" src="/static/media/logo.c734c9bbe679a8465679.png" alt="Logo"/>
            <div class="border-logo m-auto mt-3 display-none mb-5"></div>
           </div>
            <div className="heading">
             {data.h1}
            </div>

            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="para">
             {data.h1_text}
            </div>
              </div>
            </div>

            <button onClick={()=>nav('/'+data.button_text1_url)}  className="btn btn-green play-button">{data.button_text1}</button>

            <div id="star">
              <li>{data.content1}</li>
           
            
          </div>
        </div>
        <div className="col-lg-6 overflow-hidden" id="imgcol">
          <Ludo/>
        </div>
      </div>
    
    </div>
    </div>
    </Layout2>
  );
}
export default Home;
