import React from 'react'
import DashboardNav from '../Dashboard/DashboardNavbar/DashboardNav'
import logo from '../../image/logo.png'
import Ludo from '../../Component/Ludo'
import empty_referrals from '../../image/empty_referrals-history.png'
import Footer from '../../Component/Footer/Footer'

function EmptyReferralsHistory() {
  return (
    <>
    <div className="kyc-wraper">
      <div className="container-fluid">
      <div className="row">
      <div className="col-lg-4 bg-white p-0">
        <DashboardNav/>
        <div className='container' style={{display:'flex', alignItems:'center' , height:'100vh'}}>
          <div className='row m-auto'>
            <div className='col-12 m-auto ms-4 empty-notification-wraper'>
              <div className='row'>
                <div className='col-lg-8 col-md-8 m-auto mt-2'>
                  <picture>
                      <img className='img-fluid text-center' src={empty_referrals}/>
                  </picture>
                </div>
                <h2>No, transaction to show</h2>
                <p>Seems like you haven’t done activity yet  </p>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
      <div className="col-lg-8 right-section   text-center my-5">
      <div className="right-position-sticky">
      <picture>
    </picture>
    <Ludo />
      </div>
      </div>
    </div>
      </div>
    </div>
    </> 
  )
}

export default EmptyReferralsHistory
