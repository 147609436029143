import React from 'react'
import logo from '../../image/logo.png'
import DashboardNav from '../Dashboard/DashboardNavbar/DashboardNav'
import Ludo from '../../Component/Ludo'
import empty_notification from '../../image/empty_notification.png'
import '../Empty/EmptyNotification.css'
import Footer from '../../Component/Footer/Footer'

function EmptyNotification() {
    const HandleSubmit =(e) =>{
        e.preventDefault()
    }
  return (
    <>
    <div className="kyc-wraper">
      <div className="container-fluid">
      <div className="row">
      <div className="col-lg-4 bg-white p-0">
        <DashboardNav/>
        <div className='container' style={{height:'100vh' , display:'flex' , alignItems:'center'}}>
          <div className='row m-auto'>
            <div className='col-12 m-auto  empty-notification-wraper'>
              <div className='row'>
                <div className='col-lg-8 col-md-8 m-auto mt-2'>
                  <picture>
                      <img className='img-fluid' src={empty_notification}/>
                  </picture>
                </div>
                <h2>No, notification yet</h2>
                <p>Seems like you haven’t done activity yet  </p>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
      <div className="col-lg-8 right-section   text-center my-5">
      <div className="right-position-sticky">
      <picture>
      <img src={logo} />
    </picture>
    <Ludo />
      </div>
      </div>
    </div>
      </div>
    </div>
    </> 
  )
}

export default EmptyNotification
