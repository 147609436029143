import React, { Fragment, useEffect, useState } from 'react';
import ReactLoading from "https://cdn.skypack.dev/react-loading@2.0.3";
import '../Privacy-policy/PrivacyPolicy.css';
import { LUDO_API } from '../../config';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Layout from '../Layout/Layout';
import logo from '../../image/logo.png';



function ResponsibleGaming() {
    const [Responsible, setResponsible] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        try {
          const response = await axios.get(`${LUDO_API}/api/page/responsible-gaming/list`);
          const { data } = response;
          setResponsible([data]);
          setTimeout(()=>{
            setLoading(true)
          },300)
        } catch (error) {
          console.error('Error fetching privacy policy:', error);
        }
      };
      useEffect(() => {
        fetchData();
      }, []);
  return (
    <div className='privacy-policy'>
    <Layout>
      {loading ? (
        Responsible.map((record, i) => (
          <Fragment key={i}>
            <div className='banner-about'>
              <div className='container'>
                <div className='row'>
                  <div className='col-8 m-auto'>
                    <img className='logo m-auto display-none' src={logo} alt='Logo' />
                    <div className='border-logo m-auto mt-3 display-none mb-5'></div>
                    <h1>{record.h1}</h1>
                    <p className='mobile-display-none'>{record.h1_text}</p>
                    <Link className='text-decoration-none' to={record.button_text1_url}>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className='wrap-2'>
              <div className='container'>
                <div className="privacy-policy-wraper">
                  <h2>{record.content1}</h2>
                  <p>{/* Complete this with the actual content */}</p>
                </div>
              </div>
            </div>
          </Fragment>
        ))
      ) : (
        <div className='loader-wraper'>
        <ReactLoading
          type={"bars"}
          color={"#03fc4e"}
          height={100}
          width={100}
        />
     
       </div>
      )}
    </Layout>
  </div>
  )
}

export default ResponsibleGaming
