import React, { useContext } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { LUDO_API } from "../../config";
import { KycContext } from "../../context/AuthContext";


function Navbar() {
  const [login] = useState(false);
  const [data , setData] = useState([])
  const{userCrendential , setuserCrendential} = useContext(KycContext)
  const{isLogin} = userCrendential
 console.log(isLogin)
  const HandleLogout = () => {
    
    localStorage.removeItem('auth')
    setuserCrendential(prev=>({
      ...prev,
      otp:"" ,
       token :'' , 
       user_id:'' , 
       uid:'',
       isLogin:false
    }))
  
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${LUDO_API}/api/page/settings/list`);
        const { data } = response;
        setData([data]);
      } catch (error) {
        console.error('Error fetching privacy policy:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
      <div className="container-fluid">
      {
        data.map((record, i) => (
          <h1 className="navbar-brand" key={i}>
            <img src={record.logo} alt="Logo.." />
          </h1>
        ))
      }

        <div className="collapse navbar-collapse" id="mynav">
          <ul className="navbar-nav ml-auto text-center">
            <li className="nav-item">
              <Link to="/" className="nav-link active">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link active">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/faq" className="nav-link active">
                FAQ
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/how-to-play" className="nav-link active">
                How to Play
              </Link>
            </li>


            {isLogin ? (
              <>
              <li className="nav-item">
              <Link to="/dashboard" className="nav-link active">
              Go to Dashboard
              </Link>
            </li>
                <li className="nav-item logout-wraper">
                  <Link  onClick={HandleLogout} to='/login' className="nav-link active">
                    Log Out
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item login-wraper">
                  <Link to="/login" className="nav-link active">
                    Login/Sign Up
                  </Link>
                </li>
              </>
            )}
          </ul>

          {
          //   <div className="dropdown">
          //   <a
          //     className="nav-link dropdown-toggle"
          //     href="#"
          //     role="button"
          //     id="navbarDropdown"
          //     data-toggle="dropdown"
          //     data-target="#mydrp"
          //   >
          //     Language
          //   </a>
          //   <div className="dropdown-menu" id="mydrp">
          //     <li>
          //       {" "}
          //       <a className="dropdown-item" href="#">
          //         Hindi
          //       </a>
          //     </li>
          //     <li>
          //       <a className="dropdown-item" href="#">
          //         English
          //       </a>
          //     </li>
          //   </div>
          // </div>
          }
        </div>

        <div className='none' ></div>
        <ul className="mb-login-ul-wraper">
        {login ? (
          <>
            <li className="nav-item logout-wraper mb-login-logout-wraper">
              <Link to="/login" className="nav-link active">
                Log Out
              </Link>
            </li>
          </>
        ) : (
          <>
            <li className="nav-item login-wraper">
              <Link to="/login" className="nav-link active">
                Login/Sign Up
              </Link>
            </li>
          </>
        )}
        </ul>

        <button
          className="btn btn-primary m-0 collapse-btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <div
          className="offcanvas offcanvas-start canva-1"
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header">
            <button
              type="button"
              className="btn-close d-flex justify-content-center align-center"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              
            >
              {" "}
              <span className="close-popup">X</span>
            </button>
          </div>
          <div className="offcanvas-body">
            <ul>
              <li>
                <Link
                  aria-label="Close"
                  data-bs-dismiss="offcanvas"
                  className="btn-close"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  aria-label="Close"
                  data-bs-dismiss="offcanvas"
                  className="btn-close"
                  to="/about"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  aria-label="Close"
                  data-bs-dismiss="offcanvas"
                  className="btn-close"
                  to="/faq"
                >
                  FAQ
                </Link>
              </li>
              <li>
                <Link
                  aria-label="Close"
                  data-bs-dismiss="offcanvas"
                  className="btn-close"
                  to="/how-to-play"
                >
                  How to Play
                </Link>
              </li>
            </ul>
            
          </div>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
