import React, { useContext } from "react";
import Ludo from "../../../src/Component/Ludo";
import "../Dashboard/Dashboard.css";
import { Link } from "react-router-dom";
import lodoDashboardImg from '../../image/ludo_dasboard.png'
import logo from '../../image/logo.png'
import DashboardNav from "./DashboardNavbar/DashboardNav";
import KycStatus from "../../../src/KycStatus";
import Footer from "../../Component/Footer/Footer";
import { KycContext } from "../../context/AuthContext";

function Dashboard() {
  const{setLevel} = useContext(KycContext)
  const HandleClick = (data) => {
    setLevel(data)
  }
  return (
    <>
   
      <div className="dashboard-wraper">
        <div className="container-fluid">
        <div className="row">
        <div className="col-lg-4 bg-white p-0 position-relative">
          <DashboardNav/>
          <h2 className="m-0 gst-wraper">No GST/ Commission 5%/ No TDS</h2>
          <div className="dashboard-content w-100 pb-5 ">
          <p className="none m-0"></p>
             <KycStatus/>
              <div className="live-contestent-wrapper">
              <ul className="d-flex align-center">
                <span className="live-contestent-status"></span>
                <li className="list-style-none ms-2">Live Contests</li>
              </ul>
              <div className="row justify-content-between dashboard-box-wraper">
                <div className="col-lg-5 col-md-5 col-sm-12 col-12 dashboard-img-wraper">
                  <div className="prices-wraper">
                    <Link className="text-dark text-decoration-none" to='/live-battle' onClick={()=>HandleClick('low')}>
                    <p className="text-center py-3">Rs.100 - Rs.500</p>
                    </Link>
                  </div>
                  <picture>
                    <img className="img-fluid" src={lodoDashboardImg}/>
                  </picture>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 col-12 dashboard-img-wraper">
                  <div className="prices-wraper">
                    <Link className="text-dark text-decoration-none" to='/live-battle' onClick={()=>HandleClick('high')} ><p className="text-center py-3">Rs.500 - Rs.25,000</p></Link>
                  </div>
                  <picture>
                    <img className="img-fluid" src={lodoDashboardImg}/>
                  </picture>
                </div>
              </div>
              </div>
          </div>
          <Footer/>
        </div>
        <div className="col-lg-8 col-md-12 text-center my-5 right-section">
        <div className="right-position-sticky">
        <picture>
        <img src={logo} />
      </picture>
      <Ludo />
        </div>
        </div>
      </div>
        </div>
      </div>
      
    </>
  );
}

export default Dashboard;
