import React, { Fragment } from "react";
import Layout from "../Layout/Layout";
import "../Faq/Faq.css";
import { useState, useEffect } from "react";
import axios from "axios";
import logo from "../../image/logo.png";
import { Link } from "react-router-dom";
import { LUDO_API } from "../../config";
import ReactLoading from "https://cdn.skypack.dev/react-loading@2.0.3";

function Faq() {
  const [faq, setFaq] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accordion, setAccordion] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${LUDO_API}/api/page/faq/list`);
      const { data } = response;
      setFaq([data]);
      setTimeout(() => {
        setLoading(true);
      }, 300);
    } catch (error) {
      console.error("Error fetching privacy policy:", error);
    }
  };

  const fetchAcr = async () => {
    try {
      const response = await axios.get(`${LUDO_API}/api/admin/faq/list`);
      console.log(response)
      setAccordion(response.data);
    } catch (error) {
      console.error("Error fetching Accordion Data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchAcr();
  }, []);

  return (
    <>
      <div className="privacy-policy">
        <Layout>
          {loading ? (
            <Fragment>
              {faq.map((record, i) => (
                <Fragment key={i}>
                  <div className="banner-about">
                    <div className="container">
                      <div className="row">
                        <div className="col-8 m-auto">
                          <img
                            className="logo m-auto display-none"
                            src={logo}
                            alt=""
                          />
                          <div className="border-logo m-auto mt-3 display-none mb-5"></div>
                          <h1>{record.h1}</h1>
                          <p >
                            {record.h1_text}
                          </p>
                          <Link
                            className="text-decoration-none"
                            to={record.button_text1_url}
                          >
                            <button className="about-btn ">
                              <span>{record.button_text1}</span>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wrap-2">
                    <div className="container faq-wraper">
                      <h1>FAQ</h1>
                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        {
                          accordion.map((accData) => (
                          <div className="accordion-item" key={accData.id}>
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#faq-collapse-${accData.id}`}
                                aria-expanded="false"
                                aria-controls={`faq-collapse-${accData.id}`}
                              >
                                {accData.title}
                              </button>
                            </h2>
                            <div
                              id={`faq-collapse-${accData.id}`}
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div className="accordion-body">
                                {accData.content}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Fragment>
              ))}
            </Fragment>
          ) : (
            <div className="loader-wraper">
              <ReactLoading
                type={"bars"}
                color={"#03fc4e"}
                height={100}
                width={100}
              />
            </div>
          )}
        </Layout>
      </div>
    </>
  );
}

export default Faq;
