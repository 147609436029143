import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

function Protected() {
  const navigate = useNavigate();
  const isLogin = localStorage.getItem('auth');

  useEffect(() => {
    if (!isLogin) {
      navigate('/login');
    }
  }, [isLogin, navigate]);

  return isLogin ? <Outlet /> : null;
}

export default Protected;
