import React, { useContext, useEffect, useState } from "react";
import Ludo from "../../../src/Component/Ludo";
import logo from "../../image/logo.png";
import { useNavigate } from "react-router-dom";
import DashboardNav from "../Dashboard/DashboardNavbar/DashboardNav";
import "../Add-money/AddMoney.css";
import PaymentCard from "./PaymentCard";
import Footer from "../../Component/Footer/Footer";
import axios from "axios";
import { LUDO_API } from "../../config";
import { KycContext } from "../../context/AuthContext";

  function AddMoney() {
  const navigate = useNavigate();
  const [state , setState] = useState({balance:''})
  const{kycstatus} = useContext(KycContext)
  const{user_username , user_email} = kycstatus
  const [adminPhoneNo , setAdminNo] = useState('')
  console.log(adminPhoneNo)
  console.log(state)
  // const HandleSubmit = (e) => {
  //   e.preventDefault()
  //   axios.post(`${LUDO_API}/api/wallet/create` , state)
  //   .then(response=>{
  //     console.log(response)
  //   })
  //   .catch(err=>{
  //     console.log(err)
  //   })
  // }

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
  
 
    // Construct the WhatsApp message
    const message = `I want to recharge wallet\nUsername: ${user_username}\nA recharge amount: ${Number(state.balance)}\nEmail id: ${user_email}`;
  
    // Encode the message for URL
    const encodedMessage = encodeURIComponent(message);
  
    // Construct the WhatsApp URL
    const whatsappUrl = `https://wa.me/+91${adminPhoneNo}?text=${encodedMessage}`;
  
    // Redirect to WhatsApp
    window.open(whatsappUrl, '_blank');
  };
  
useEffect(()=>{
  axios.get(LUDO_API + "/api/page/settings/list")
  .then(response=>{
    console.log(response.data)
    setAdminNo(response.data.phone)
  })
  .catch(err=>{
    console.log(err)
  })
},[])
  return (
    <>
      
      <div className="dashboard-wraper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 bg-white p-0 pb-5 position-relative">
              <DashboardNav />
              <div className="dashboard-content w-100 ">
                <div className="kyc-status-wrapper add-money-wraper  ">
                  
                  <h2>
                    News:- Recharge your wallet without GST, now there’s no
                    extra charge.
                  </h2>
                  <div className="border"></div>
                </div>
              </div>
            <form onSubmit={handleSubmit }>
              <div className="container m-auto" style={{ width: "80%" }}>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 m-auto">
                    <div className="amount-wraper w-100">
                      <label htmlFor="add-money">Enter Amount</label> <br />
                      
                      <input
                      type="number"
                      id="add-money "
                      style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        borderBottom: "1px solid  #5F5A5A",
                        padding: "10px 0",
                      }}
                      placeholder="Rs."
                      onChange={(e)=>setState({...state , balance:e.target.value})}
                    />
                      
                      <h2>Min. 100 , Max. 25,000</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="payment-card-wraper">
                <div className="container">
                  <div className="row justify-content-between">
                    <PaymentCard />
                    <PaymentCard />
                    <PaymentCard />
                    <PaymentCard />

                    <button>
                      <span>Pay Now</span>
                    </button>
                  </div>
                </div>
              </div>

              </form>

              <Footer />
            </div>
            <div className="col-lg-8 col-md-12 text-center my-5 right-section">
              <div className="right-position-sticky">
                <picture>
                  <img src={logo} />
                </picture>
                <Ludo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddMoney;
