import React, { Fragment, useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import "../How_to_play/HowToPlay.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { LUDO_API } from "../../config";
import logo from "../../image/logo.png";
import ReactLoading from "https://cdn.skypack.dev/react-loading@2.0.3";

function HowToPlay() {
  const [howToPlayData, setHowToPlayData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${LUDO_API}/api/page/how-to-play/list`
        );
        const { data } = response;
        setHowToPlayData([data]);
        setTimeout(() => {
          setLoading(true);
        }, 300);
      } catch (error) {
        console.error("Error fetching privacy policy:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="privacy-policy">
      <Layout>
        {loading ? (
          howToPlayData.map((record, i) => (
           <Fragment key={i}>
            <div  className="banner-about">
              <div className="container">
                <div className="row">
                  <div className="col-8 m-auto">
                    <img className="logo m-auto display-none" src={logo} alt="Logo" />
                    <div className="border-logo m-auto mt-3 display-none mb-5"></div>
                    <h1>{record.h1}</h1>
                    <p className="mobile-display-none">{record.h1_text}</p>
                    <Link className="text-decoration-none" to={record.button_text1_url}>
                      <button className="about-btn mobile-display-none">
                        <span>{record.button_text1}</span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
             
            </div>
            <div className="wrap-2">
            <div className="container">
              <div className="privacy-policy-wraper">
                <h2>{record.content1}</h2>
                <p>{/* Complete this with the actual content */}</p>
              </div>
            </div>
          </div>
           </Fragment>
          ))
        ) : (
          <div className='loader-wrapper loader-wraper-play'>
            <ReactLoading
              type={"bars"}
              color={"#03fc4e"}
              height={100}
              width={100}
            />
          </div>
        )}
      </Layout>
    </div>
  );
}

export default HowToPlay;
