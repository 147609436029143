import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { KycContext } from "./context/AuthContext";

function KycStatus() {
  const navigate = useNavigate();
  const { userCrendential } = useContext(KycContext);
  console.log(userCrendential);

  const { kycstatus } = useContext(KycContext);
  console.log(kycstatus);

  return kycstatus.status === "approved" ? (
    ""
  ) : kycstatus.status === "inprocess" ? (
    <p className="px-4 text-danger text-justify text-capatilize">
      Thank you for submitting your KYC details. Your information is now under
      review by our admin team. We appreciate your patience and will notify you
      once your approval process is complete. If you have any urgent concerns,
      please feel free to reach out to our support team. Thank you!
    </p>
  ) : (
    <div className="kyc-status-wrapper  ">
      <div className="container d-flex justify-content-evenly h-100 align-center">
        <p>KYC Pending !</p>
        <button onClick={() => navigate("/kyc")}>
          <span>Complete here</span>
        </button>
      </div>
    </div>
  );
}

export default KycStatus;
