import React, { useContext, useEffect, useState } from "react";
import profile from "../../../image/Account.png";
import cashImg from "../../../image/Cash.png";
import walletImg from "../../../image/Wallet.png";
import avtar from "../../../image/avtar.jpg";
import hamburger from "../../../image/hamburger.svg";
import transactionImg from "../../../image/Transaction.png";
import diceImg from "../../../image/Dice.png";
import { Link } from "react-router-dom";
import cashImg2 from "../../../image/CashinHand.png";
import supportImg from "../../../image/fluent_person-support-20-regular@2x.png";
import wallet_pic from "../../../image/wallet-pic.png";
import logout from "../../../image/logout.svg";
import axios from "axios";
import { LUDO_API } from "../../../config";
import { KycContext } from "../../../context/AuthContext";


function DashboardNav() {
 const {userCrendential , setuserCrendential} = useContext(KycContext)
 const[balance , setBalance] = useState('')
 console.log(userCrendential)
  useEffect(()=>{
    axios.get(LUDO_API + "/api/wallet/get-total-balance-of-user?user_id=" + userCrendential.user_id)
    .then(response=>{
      setBalance(response.data.total_balance)
    })
    .catch(err=>{
      console.log(err)
    })
  },[userCrendential])

  const HandleLogout = () => {
    
    localStorage.removeItem('auth')
    setuserCrendential(prev=>({
      ...prev,
      otp:"" ,
       token :'' , 
       user_id:'' , 
       uid:'',
       isLogin:false
    }))
  
  }

  return (
    <>
      <div className="dashboard-nav d-flex align-center">
        <div className="container  d-flex justify-content-between p-0">
        
          <div>
          <a 
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample" style={{cursor:"pointer"}}><img src={hamburger}/>
          </a>
          <Link to='/'>
          <img className="avtar-img" src={avtar}/>
          </Link>
          </div>
          <Link to="/add-money ">
            {" "}
            <div>
              <img src={wallet_pic} />
              &nbsp;
              <span className="fw-bold ">{balance} </span>{" "}
              
                <span className="fw-bold ">+</span>
             
            </div>
          </Link>
          <div
            className="offcanvas offcanvas-start canva-2-dashboard"
            tabIndex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasExampleLabel"></h5>
              <button
                type="button"
                className="btn-close fw-bold  d-flex justify-content-center align-center"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="offcanvas-body">
              <div className="dashboard-nav-list">
                <ul>
                  <li>
                    <span className="nav-list-img-wraper d-flex align-center justify-content-center">
                      <picture>
                        <img className="img-fluid" src={profile} />
                      </picture>
                    </span>
                    <Link
                      aria-label="Close"
                      data-bs-dismiss="offcanvas"
                      to='/my-profile'
                      >
                      My Account
                    </Link>
                  </li>
              {
              //   <li>
              //   <span className="nav-list-img-wraper d-flex align-center justify-content-center">
              //     <picture>
              //       <img className="img-fluid" src={cashImg} />
              //     </picture>
              //   </span>
              //   <Link  aria-label="Close"
              //   data-bs-dismiss="offcanvas" to='/dashboard'>Win Cash</Link>
              // </li>
              }
                  <li>
                    <span className="nav-list-img-wraper d-flex align-center justify-content-center">
                      <picture>
                        <img className="img-fluid" src={walletImg} />
                      </picture>
                    </span>
                    <Link
                      aria-label="Close"
                      data-bs-dismiss="offcanvas"
                      to="/wallet-profile/"
                    >
                      Wallets
                    </Link>
                  </li>
                  <li>
                    <span className="nav-list-img-wraper d-flex align-center justify-content-center">
                      <picture>
                        <img className="img-fluid" src={transactionImg} />
                      </picture>
                    </span>
                    <Link
                      aria-label="Close"
                      data-bs-dismiss="offcanvas"
                      to="/transaction"
                    >
                      Transaction History
                    </Link>
                  </li>
                  <li>
                    <span className="nav-list-img-wraper d-flex align-center justify-content-center">
                      <picture>
                        <img className="img-fluid" src={diceImg} />
                      </picture>
                    </span>
                    <Link
                      aria-label="Close"
                      data-bs-dismiss="offcanvas"
                      to="/game-history"
                    >
                      Games History
                    </Link>
                  </li>
                  <li>
                    <span className="nav-list-img-wraper d-flex align-center justify-content-center">
                      <picture>
                        <img className="img-fluid" src={cashImg2} />
                      </picture>
                    </span>
                    <Link
                      aria-label="Close"
                      data-bs-dismiss="offcanvas"
                      to="/refer-earn"
                    >
                      Earn & Refers
                    </Link>
                  </li>
                  <li>
                    <span className="nav-list-img-wraper d-flex align-center justify-content-center">
                      <picture>
                        <img className="img-fluid" src={profile} />
                      </picture>
                    </span>
                    <Link
                      aria-label="Close"
                      data-bs-dismiss="offcanvas"
                      to="/empty-referrals-history"
                    >
                      Referral History
                    </Link>
                  </li>
                  <li>
                    <span className="nav-list-img-wraper d-flex align-center justify-content-center">
                      <picture>
                        <img className="img-fluid" src={profile} />
                      </picture>
                    </span>
                    <Link
                      aria-label="Close"
                      data-bs-dismiss="offcanvas"
                      to="/empty-referrals-history"
                    >
                      Penalty History
                    </Link>
                  </li>
                  <li>
                    <span className="nav-list-img-wraper d-flex align-center justify-content-center">
                      <picture>
                        <img className="img-fluid" src={profile} />
                      </picture>
                    </span>
                    <Link
                      aria-label="Close"
                      data-bs-dismiss="offcanvas"
                      to="/empty-notification"
                    >
                      Notifications
                    </Link>
                  </li>
                  <li>
                    <span className="nav-list-img-wraper d-flex align-center justify-content-center">
                      <picture>
                        <img className="img-fluid" src={supportImg} />
                      </picture>
                    </span>
                    <Link
                      aria-label="Close"
                      data-bs-dismiss="offcanvas"
                      to="/support"
                    >
                      Support
                    </Link>
                  </li>
                  <li className="d-flex align-center">
                  <span className=" d-flex align-center justify-content-center">
                    <picture>
                      <img className="img-fluid" src={logout} />
                    </picture>
                  </span>
                  <Link
                    aria-label="Close"
                    data-bs-dismiss="offcanvas"
                    onClick={HandleLogout}
                  >
                    Logout
                  </Link>
                </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardNav;
