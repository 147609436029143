import React from "react";
import "./Footer.css";
import insta from "../../image/Instagram.png";
import facebook from "../../image/Facebook.png";
import YouTube from "../../image/YouTube.png";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { LUDO_API } from "../../config";

function Footer() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${LUDO_API}/api/page/settings/list`);
        const { data } = response;
        setData([data]);
      } catch (error) {
        console.error("Error fetching privacy policy:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <footer className="white-footer-wraper ">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-2 text-lg-end text-start ">
            <h2 className="text-dark text-start m-0">Disclaimer :-</h2>
          </div>
          {data.map((record, i) => (
            <div className="col-lg-12 col-md-12 disclaimer-wraper" key={i}>
              <p>{record.disclaimer }</p>
            </div>
          ))}
          {data.map((record, i) => (
            <div className="socialbox d-flex align-center" key={i}>
              <div className="innersocialbox1 text-dark fw-bold">
                Follow Us :-
              </div>
              <Link to={record.instagram} className="logosocial">
                <img src={insta} alt="Instagram" />
              </Link>
              <Link to={record.facebook} className="logosocial">
                <img src={facebook} alt="Facebook" />
              </Link>
              <Link to={record.youtube} className="logosocial">
                <img src={YouTube} alt="YouTube" />
              </Link>
            </div>
          ))}
          <div className="col-lg-1 col-md-1"></div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-6 mt-3">
            <Link className="text-decoration-none " to="/privacy-policy">
              <p className="ms-3 mb-0">Privacy Policy</p>
            </Link>
          </div>
          <div className="col-6 mt-3">
            <Link className="text-decoration-none " to="/refund-policy">
              <p className="ms-3 mb-0">Refund Policy</p>
            </Link>
          </div>
          <div className="col-6 mt-3">
            <Link className="text-decoration-none " to="/contact-us">
              <p className="ms-3 mb-0">Contact</p>
            </Link>
          </div>
          <div className="col-6 mt-3">
            <Link className="text-decoration-none " to="/responsible-gaming">
              <p className="ms-3 mb-0">Responsible Gaming</p>
            </Link>
          </div>
          <div className="col-6 mt-3">
            <Link className="text-decoration-none " to="/terms-conditions">
              <p className="ms-3 mb-0">Terms & Conditions</p>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
