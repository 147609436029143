import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import '../room-code/CreatePopup.css';
import playstore_img from '../../image/room_playstore.png';

function CreateRoomCodePupup(props) {
  console.log('props_id' + props)
  const [open, setOpen] = useState(true); // Controls the open state of the Dialog


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open}  maxWidth="sm" fullWidth>
    
      <div className='create-room-popup'>
      <button className='close-create-roompopup' onClick={handleClose}>X</button>

        <p className='create-room-popup-heading-section'>
          <strong>News</strong>: Recharge your wallet without GST, now there’s no extra charge.
        </p>

        <form>
          <div className='create-room-popup-popup-section'>
            <h2>Room Code</h2>
            <input autoFocus placeholder='Enter code' />
            <button className='set-room-code-btn'>Set Room Code</button>
          </div>
        </form>

        <div className='play-store-img-wraper'>
          <button className='play-store-img'>
            <img src={playstore_img} alt='Play Store' />
          </button>
          <button className='play-store-img'>
            <img src={playstore_img} alt='Play Store' />
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default CreateRoomCodePupup;
