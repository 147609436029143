import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

function Layout2({children}) {
  return (
    <>
      <Header/>
            {children}
      <Footer/>
    </>
  )
}

export default Layout2
