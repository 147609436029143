import React from 'react'
import logo from '../../image/logo.png'
import Ludo from '../../Component/Ludo'
import DashboardNav from './DashboardNavbar/DashboardNav'
import '../Dashboard/support.css'
import support_img from '../../image/support.png'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useEffect , useState} from 'react'
import { LUDO_API } from '../../config'
import Footer from '../../Component/Footer/Footer'



function Support() {
  const [support, setSupport] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${LUDO_API}/api/page/settings/list`);
        const { data } = response;
        setSupport(data);
      } catch (error) {
        console.error('Error fetching privacy policy:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
    <div className="kyc-wraper">
      <div className="container-fluid">
      <div className="row">
      <div className="col-lg-4 bg-white p-0">
        <DashboardNav/>
        <div className='container pb-5' style={{height:'100vh' , display:'flex' , alignItems:'center'}}>
          <div className='row justify-content-center'>
            <div className='col-12 m-auto ms-4 support-wraper'>
              <div className='row'>
                <div className='col-lg-8 col-md-8 m-auto mt-2'>
                  <picture>
                      <img className='img-fluid' src={support_img}/>
                  </picture>
                </div>
                <Link className='d-block text-center text-decoration-none'>{support.whatsapp1}</Link>
                <Link className='d-block text-center text-decoration-none'>{support.whatsapp2}</Link>
                <Link className='d-block text-center text-decoration-none'>{support.whatsapp3} </Link>
                <p>Supporting timing is from {support.support_time}</p>
                <p>( {support.days} )</p>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
      <div className="col-lg-8 right-section   text-center my-5">
      <div className="right-position-sticky">
      <picture>
      <img src={logo} />
    </picture>
    <Ludo />
      </div>
      </div>
    </div>
      </div>
    </div>
    </> 
  )
}

export default Support
