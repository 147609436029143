import React from 'react'
import Navbar from '../Navbar/Navbar'

function Header() {
  return (
    <>
       <Navbar/>
    </>
  )
}

export default Header
