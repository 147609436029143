import React from 'react'

function PaymentCard() {
  return (
    <div className="col-5 col-md-5 col-sm-12 col-12 payment-card-details-wraper mt-5">
    <div className="payment-card">
     <h2>Rs.</h2>
     <h2>100</h2>
    </div>
    </div>
  )
}

export default PaymentCard
