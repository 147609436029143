
import React, { useState, useEffect , useContext } from "react";

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import Layout2 from "../Layout/Layout2";
import ludo from "../../image/ludo.png";
import "../Auth/Login.css";
import login_img from "../../image/login-img.png";
import logo from "../../image/logo.png";
import app from "../../firebase";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LUDO_API } from "../../config";
import { KycContext } from "../../context/AuthContext";

const App = () => {
  const {setuserCrendential} = useContext(KycContext)
  const auth = getAuth(app);
  const nav = useNavigate()

  const [phone, setPhone] = useState("+91");
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");

  console.log(phone)

  useEffect(() => {
    generateRecaptcha();
  }, []); // Run once when component mounts

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
      },
      auth
    );
  };

  const handleSend = (event) => {
    event.preventDefault();
    setHasFilled(true);
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifyOtp = (event) => {
    let otp = event.target.value;
    setOtp(otp);

    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          let user = result.user;
          console.log(user);
          const{uid,phoneNumber,accessToken} = result.user;
          console.log('user id is' + uid)
          console.log('user mobile-number is' + phoneNumber)
          console.log('user token is' + accessToken)
          alert("User signed in successfully");
          axios.post(`${LUDO_API}/api/store-otp-verification-data`,{
            phone_number:phoneNumber,
            otp:otp,
            uid:uid
          })
          .then(response=>{
            console.log(response)
            setuserCrendential(prev=>({
              ...prev,
              otp:otp,
              uid:uid,
              token:accessToken,
              user_id:response.data.data.user_id,
              isLogin:true
            }))

            localStorage.setItem('auth', JSON.stringify({
              otp:otp,
              uid:uid,
              token:accessToken,
              user_id:response.data.data.user_id,
              isLogin:true
            }))
            nav('/dashboard')
          })
        
          .catch(err=>{
            console.log(err)
          })
         
         
        })
        .catch((error) => {
          alert("User couldn't sign in (bad verification code?)" + error);
          console.log(error)
        });
    }
  };
  if (!hasFilled) {
    return (
      <>
      <Layout2>
        <div className="banner">
          <div className="container-fluid">
            <div className="row ">
              <div className="col-lg-4 col-md-7 col-sm-7 ">
                <div className="container-fluid">
                  <img className="logo  m-auto display-none mt-5 img-fluid " src={logo} />
                  <div className="border-logo m-auto mt-3 display-none"></div>

                 

                  <div className="row col-9-form-width">
                    <img className="display-none img-fluid" src={login_img} />
                    <div className="col-lg-9 col-md-12 col-sm-12 login-form-wraper">
                      <h2>Login/Signup</h2>
                      <p>Enter Mobile Number</p>
                      <form onSubmit={handleSend}>
                        <input
                          className="w-100"
                          type="text"
                          placeholder="+91"
                          value={phone}
                          onChange={(event) => setPhone(event.target.value)}
                        />
                        <button className="w-100 submit-btn">
                          <span>GET OTP</span>
                        </button>
                      </form>
                    </div>
                   
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-5 col-sm-5" id="imgcol">
               <div className=" w-100 d-flex justify-content-end">
               <img id="img" className="img-fluid text-right" src={ludo} alt="" />
               </div>
            </div>
            </div>
          </div>
        </div>
        </Layout2>
        <div id="recaptcha"></div>
      </>
    );
  } else {
    return (
      <>
        <Layout2>
        <div className="banner">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-7 col-sm-7 m-auto">
              <div className="container-fluid">
                <img className="logo  m-auto display-none mt-5" src={logo} />
                <div className="border-logo m-auto mt-3 display-none"></div>

                <h2 className="mobile-view-heading-wraper my-5 display-none">
                  Participate in Online Ludo and Earn Cash Prizes.
                </h2>

                <div className="row">
                  <img className="display-none img-fluid " src={login_img} />

                  <div className="col-lg-7 col-md-12 col-sm-12 login-form-otp-wraper">
                  <p>
                    Please enter the otp sent to your registered mobile
                    number
                 </p>
                    <input type="text" value={otp} onChange={verifyOtp} placeholder='Enter six digit otp 
                    code' className='w-100'/>
                    <button className="w-100 submit-btn">
                    <span>login</span>
                  </button>
                  </div>
                 
                  <div className="my-5">
                    <p className="text-light">*Instant Withdrawal</p>
                    <p className="text-light">*Terms & Conditions Apply</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 col-sm-5" id="imgcol">
            <img id="img" className="img-fluid text-right" src={ludo} alt="" />
          </div>
          </div>
        </div>
      </div>
        </Layout2>
        <div id="recaptcha"></div>
      </>
    );
  }
};

export default App;
