import React, { useContext, useEffect, useState } from 'react'
import DashboardNav from '../Dashboard/DashboardNavbar/DashboardNav'
import logo from '../../image/logo.png'
import Ludo from '../../Component/Ludo'
import '../KycDashboard/kyc.css'
import Footer from '../../Component/Footer/Footer'
import { KycContext } from '../../context/AuthContext'
import axios from 'axios'
import { LUDO_API } from '../../config'

function Kyc() {
  const {userCrendential}  = useContext(KycContext)
  const[formData , setFormData] = useState({
    user_id:'',
    aadhar_number:'',
    email:'',
    adhar_image:''
  })

 console.log(formData)

 const handleChange = (e) => {

    setFormData({
      ...formData,
      [e.target.name]:e.target.value
     })
   
 }

 const fileChange = (e) => {
 setFormData({
  ...formData,
  adhar_image:e
 })
 }
  
  const HandleSubmit = (e) => {
    e.preventDefault()
    console.log('clicked')
    axios.post(LUDO_API + "/api/kyc/create",formData)
    .then(response=>{
      console.log(response)
      if (response.status===201) {
        
      }
    })
    .catch(err=>{
      console.log(err)
    })
  }
  useEffect(()=>{
    setFormData({
      ...formData,
      user_id:userCrendential.user_id
    })
  },[userCrendential])
  return (
    <>
<div className="kyc-wraper">
  <div className="container-fluid">
  <div className="row">
  <div className="col-lg-4 bg-white p-0">
    <DashboardNav/>
    <div className='container pb-5'>
      <div className='row'>
        <div className='col-12 m-auto ms-4'>
        <h2>Update KYC</h2>
        </div>
        <div className='col-10 m-auto ms-4'>
          <form onSubmit={HandleSubmit}>
          <label><input  type='file' onChange={(e)=>fileChange(e.target.files[0])}/></label>
         <label><input className='d-block' name="email" type='email' placeholder='Enter your email address' onChange={handleChange}/></label>
         <label> <input className='d-block' name="aadhar_number" type='number' placeholder='Enter your aadhar number' onChange={handleChange}/></label>
          <button className='w-100 submit-btn submit-otp-btn'><span>UPDATE KYC</span></button>
          </form>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
  <div className="col-lg-8 col-md-12 text-center my-5 right-section">
  <div className="right-position-sticky">
  <picture>
  <img src={logo} />
</picture>
<Ludo />
  </div>
  </div>
</div>
  </div>
</div>
</>  
  )
}

export default Kyc
