import React from 'react'
import '../BlueFooter/BlueFooter.css'
import insta from '../../../image/Instagram.png'
import facebook from '../../../image/Facebook.png'
import YouTube from '../../../image/YouTube.png'
import { Link } from 'react-router-dom'

function BlueFooter() {
  return (
    <>
      <footer className='blue-footer-wraper '>
        <div className='container-fluid'>
           <div className='row'>
                <div className='col-lg-2 col-md-2 text-lg-end '>
                <h2>Disclaimer :-</h2>
                </div>
                <div className='col-lg-9 col-md-9 disclaimer-wraper'>
                <p>By proceeding, you agree to our Terms of Use, Privacy Policy and that you are 18 years or older. You are not playing from Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, or Telangana. 
                This game may be habit-forming or financially risky. Play responsibly.</p>
                </div>
                <div className='col-lg-1 col-md-1'></div>
           </div> 
        </div>
        <div className='container'>
            
            <div className='policy d-flex justify-content-between'>
               <div className='d-flex policy-content '>
                <Link className='text-decoration-none' to='/terms-conditions'><p className='mb-0'>Terms & Condition</p></Link>
                <Link className='text-decoration-none' to='/privacy-policy'><p className='ms-3 mb-0'>Privacy Policy</p></Link>
                <Link className='text-decoration-none' to='/refund-policy'><p className='ms-3 mb-0'>Refund Policy</p></Link>
                <Link className='text-decoration-none' to='/contact-us'><p className='ms-3 mb-0'>Contact</p></Link>
                <Link className='text-decoration-none ' to='/responsible-gaming'><p className='ms-3 mb-0'>Responsible Gaming</p></Link>
               </div>
                <div className="socialbox d-flex align-center ">
                <div className="innersocialbox1 text-white fw-bold">Follow Us :-</div>
                 <a href="" className="logosocial"><img src={insta}/></a> 
                 <a href="" className="logosocial"><img src={facebook} /></a>
                <a href="" className="logosocial"><img src={YouTube}  /></a> 
               </div>
            </div>
          
           
        </div>
      </footer>
    </>
  )
}

export default BlueFooter
